/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'

import classNames from 'classnames'

// import Charts
import StackedColumnChart from './StackedColumnChart'

// import action
import { getChartsData as onGetChartsData } from '../../store/actions'
import moment from 'moment'
import modalimage1 from '../../assets/images/product/img-7.png'
import modalimage2 from '../../assets/images/product/img-4.png'

// Pages Components
import WelcomeComp from './WelcomeComp'
import NumberFormat from 'react-number-format'
import MonthlyEarning from './MonthlyEarning'
import SocialSource from './SocialSource'
import ActivityComp from './ActivityComp'
import TopCities from './TopCities'
import LatestTranaction from './LatestTranaction'
import TableComponent from 'components/Table'
// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

// i18n

// redux
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../components/Loader'
import { GetData } from 'apiHandler'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const Dashboard = props => {
  const [loader, setLoader] = useState(false)
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [cardsData, setCardsData] = useState(null)
  const [transactions, setTransactions] = useState([])
  const userObj = JSON.parse(localStorage.authUser)
  const history = useHistory()

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }))

  const paymentStatus = {
    0: 'Pending',
    1: 'Partially Paid',
    2: 'Paid'
  }

  const reports = [
    { title: 'Orders', iconClass: 'bx-copy-alt', description: '1,235' },
    { title: 'Revenue', iconClass: 'bx-archive-in', description: '$35, 723' },
    {
      title: 'Average Price',
      iconClass: 'bx-purchase-tag-alt',
      description: '$16.2'
    }
  ]

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSubscribemodal(true);
  //   }, 2000);
  // }, []);

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState('yearly')

  const companyName = JSON.parse(localStorage.getItem('authUser')).companyName

  const [columns, setColumns] = useState([
    { key: 'po_number', label: 'P.O. Number' },
    { key: 'orderNumber', label: 'Order' },
    { key: 'createdAt', label: 'Order Date' },
    { key: 'customerName', label: 'Customer Name' },
    { key: 'paymentStatus', label: 'Payment Status' },
    { key: 'costPriceTotal', label: 'Price Total' }
  ])

  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })

  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  useEffect(() => {
    getDashboardDetails()
    getTransactions(1)
  }, [])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const handleRow = (data) => {
    history.push('/orders/' + data.id)
  }

  const getDashboardDetails = () => {
    let url = 'admin/getDashboardDetails'

    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = 'vendor/getDashboardDetails'
    }

    setLoader(true)
    GetData(url)
      .then((response) => {
        if (response.status && response.code === 200) {
          setLoader(false)
          setCardsData(response.result)
        } else {
          setLoader(false)
          toastr.error('Error', response.message)
        }
      })
  }

  const paginationHandler = (page) => {
    getTransactions(page)
  }

  const getTransactions = (p = 1) => {
    const pageSize = 20
    let url = 'admin/getDashboardTransaction?page=' + p + '&pageSize=' + pageSize

    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = 'vendor/getDashboardTransaction?page=' + p + '&pageSize=' + pageSize
    }

    setLoader(true)
    GetData(url)
      .then((response) => {
        if (response.status && response.code === 200) {
          (userObj.roleId === 3 || userObj.roleId === 4)
            ? setTransactions(response.result.vendorOrders.map((order, index) => {
              let statusString = <div className="active-div">Delivered</div>
              if (order.paymentStatus === 0) {
                statusString = <div className="disabled-div">Pending</div>
              }
              if (order.paymentStatus === 1) {
                statusString = <div className="disabled-div">Partially Paid</div>
              }
              if (order.paymentStatus === 2) {
                statusString = <div className="active-div">Paid</div>
              }

              const totalPrice = order.costPriceTotal !== null
                ? <NumberFormat
              value={`${(order.costPriceTotal !== '' && parseFloat(order.costPriceTotal)) + (order?.parentOrder?.shipping !== '' && parseFloat(order?.parentOrder?.shipping))}`}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              prefix={'$'}
            />
                : '--'

              return {
                id: order.id,
                orderNumber: order.parentOrder.orderNumber,
                createdAt: moment(order.createdAt).format('Do MMMM YYYY, h:mm A'),
                customerName: `${order.parentOrder.customer.primaryFirstName} ${order.parentOrder.customer.primaryLastName}`,
                paymentStatus: statusString,
                costPriceTotal: totalPrice,
                po_number: order?.parentOrder?.po_number,
              }
            }))
            : setTransactions(response.result.orders.map((order, index) => {
              let statusString = <div className="active-div">Delivered</div>
              if (order.paymentStatus === 0) {
                statusString = <div className="disabled-div">Pending</div>
              }
              if (order.paymentStatus === 1) {
                statusString = <div className="disabled-div">Partially Paid</div>
              }
              if (order.paymentStatus === 2) {
                statusString = <div className="active-div">Paid</div>
              }

              const totalPrice = order.total !== null
                ? <NumberFormat
              value={`${(order.total !== '' && parseFloat(order.total)) + (order?.parentOrder?.shipping !== '' && parseFloat(order?.parentOrder?.shipping))}`}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              prefix={'$'}
            />
                : '--'

              return {
                id: order.id,
                orderNumber: order.parentOrder.orderNumber,
                createdAt: moment(order.createdAt).format('Do MMMM YYYY, h:mm A'),
                customerName: `${order.parentOrder.customer.primaryFirstName} ${order.parentOrder.customer.primaryLastName}`,
                paymentStatus: statusString,
                costPriceTotal: totalPrice,
                po_number: order?.parentOrder?.po_number,
              }
            }))

          setLoader(false)

          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.total
          })
        } else {
          setLoader(false)
          toastr.error('Error', response.message)
        }
      })
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData('yearly'))
  }, [dispatch])

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  return (
    <React.Fragment>
      <Loader show={loader} />
      <div className="page-content">
        <MetaTags>
          <title>Dashboard</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {
            cardsData && <>
            <div className='d-flex align-items-center justify-content-between'>
            <Breadcrumbs
            title={('Dashboards')}
            breadcrumbItem={('Dashboard')}
          />
          {(userObj.roleId === 3 || userObj.roleId === 4) && <h2 className='m-0 text-dark'>{companyName}</h2>}
          </div>
          <Row className='mt-3'>
            <Col lg={3}>
                <Card>
                <CardBody className='d-flex align-items-center justify-content-between'>
                        <div>
                            <p>Total Revenue</p>
                            <p className='mb-0 h3'>
                        <NumberFormat
                        value={cardsData?.totalRevenue}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={'$'}
                      /></p>
                        </div>
                        <div className='invoicing-icon-container'>
                            <i className='mdi mdi-file-chart invoicing-icon'></i>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={3}>
            <Card>
            <CardBody className='d-flex align-items-center justify-content-between'>
                        <div>
                            <p>Total Orders</p>
                            <p className='mb-0 h3'><NumberFormat
                        value={
                          cardsData?.totalOrders}
                        displayType={'text'}
                        thousandSeparator={true}
                      /></p>
                        </div>
                        <div className='invoicing-icon-container'>
                            <i className='mdi mdi-file-chart invoicing-icon'></i>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={3}>
                <Card>
                <CardBody className='d-flex align-items-center justify-content-between'>
                        <div>
                            <p>Current Month Earnings</p>
                            <p className='mb-0 h3'><NumberFormat
                        value={cardsData?.thisMonthEarning}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={'$'}
                      /></p>
                        </div>
                        <div className='invoicing-icon-container'>
                            <i className='mdi mdi-cart-check invoicing-icon'></i>
                        </div>
                    </CardBody>
                    </Card>
            </Col>
            <Col lg={3}>
            <Card>
                    <CardBody className='d-flex align-items-center justify-content-between'>
                        <div>
                            <p>Change Previous Month</p>
                            <p className='mb-0 h3'><NumberFormat
                        value={cardsData?.changeFromPreviousMonth}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        suffix={'%'}
                      /></p>
                        </div>
                        <div className='invoicing-icon-container'>
                            <i className='mdi mdi-file-excel invoicing-icon'></i>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        </>
          }
          {
            !loader && <Breadcrumbs
            title={('Latest Transactions')}
            breadcrumbItem={('Latest Transactions')}
          />
          }
          {/* <Row>
            <Col xl="4">
              <WelcomeComp />
              <MonthlyEarning />
            </Col>
            <Col xl="8">
              <Row>
                {reports.map((report, key) => (
                  <Col md="4" key={'_col_' + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  'bx ' + report.iconClass + ' font-size-24'
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Email Sent</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === 'weekly' },
                              'nav-link'
                            )}
                            onClick={() => {
                              onChangeChartPeriod('weekly')
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{' '}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === 'monthly' },
                              'nav-link'
                            )}
                            onClick={() => {
                              onChangeChartPeriod('monthly')
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === 'yearly' },
                              'nav-link'
                            )}
                            onClick={() => {
                              onChangeChartPeriod('yearly')
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <StackedColumnChart periodData={periodData} />
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          {/* <Row>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>

            <Col xl="4">
              <TopCities />
            </Col>
          </Row> */}

          <Row className='mt-3'>
            <Col lg="12">
            <TableComponent
                    columns={columns}
                    content={transactions}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    sorting={handleSort}
                    onClick={handleRow}
                    arrowState={sortingState}
                  />
            </Col>
          </Row>

        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal)
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
}

export default Dashboard
