/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef
} from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Label,
  FormFeedback,
  Form,
  UncontrolledTooltip
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import classnames from 'classnames'
import {
  GetData,
  PutData,
  ToesterSettings,
  DeleteData,
  PostData
} from '../../apiHandler'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import ProfileModal from 'components/Common/ProfileModal'
import ProfileViewModal from 'components/Common/ProfileViewModal'
import LocationModal from 'components/Common/LocationModal'
import LocationViewModal from 'components/Common/LocationViewModal'
import './customer.scss'
import { isEmpty } from 'lodash'
import tickIcon from '../../assets/images/verified.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import delIcon from '../../assets/images/ic_delete.svg'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import OrderList from './OrderList'
import StaffList from './StaffList'
import PaymentInfoList from './PaymentInfoList'
import DisableModal from 'components/Common/DisableModal/DisableModal'
import AssignBrandsCategories from './AssignBrandsCategories'

export const LocationModalContext = createContext()
export const ProfileModalContext = createContext()

export default function ActiveCustomer (props) {
  toastr.options = ToesterSettings
  const params = useParams()
  const history = useHistory()

  const [loader, setLoader] = useState(false)
  const [tab, setTab] = useState('1')
  const [userData, setUserData] = useState({})
  const [profileData, setProfileData] = useState({})
  const [businessType, setBusinessType] = useState({})
  const [ownershipType, setOwnershipType] = useState({})
  const [chainAffilation, setChainAffilation] = useState(null)
  const [locations, setLocations] = useState([])
  const [locationModal, setLocationModal] = useState(false)
  const [profileModal, setProfileModal] = useState(false)
  const [profileViewModal, setProfileViewModal] = useState(false)
  const [locationData, setLocationData] = useState({})
  const [activeStatus, setActiveStatus] = useState(true)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [displayOrders, setDisplayOrders] = useState(false)
  const [displayStaff, setDisplayStaff] = useState(false)
  const [displayPaymentInfoList, setDisplayPaymentInfoList] = useState(false)
  const customerNotesRef = useRef()
  const [disableModal, setDisableModal] = useState(false)
  const [displayAssign, setDisplayAssign] = useState(false)
  const [isCopied, setIsCopied] = useState(false);
  const [allLocations, setAllLocations] = useState([])

  useEffect(() => {
    getCustomerData(params.id)
    getCustomerLocations()
    const commonData = JSON.parse(localStorage.commonData)
    if (commonData.businessType) {
      setBusinessType(commonData.businessType)
    }
    if (commonData.ownershipType) {
      setOwnershipType(commonData.ownershipType)
    }
    if (commonData.chainAffilation) {
      setChainAffilation(commonData.chainAffilation)
    }
  }, [])

  const saveProfile = (editedData) => {
    setLoader(true)
    const payload = {
      documentFile: editedData?.customerDocuments?.[0]?.name || null,
      ...editedData
    }
    PutData('admin/updateCustomerProfile/' + params.id, payload).then(
      (response) => {
        setLoader(false)
        if (response.status === true) {
          getCustomerData(params.id)
          toastr.success(response.message, 'Success')
          setProfileModal(false)
        } else {
          toastr.error(response.message, 'Error')
        }
      }
    )
  }

  const getCustomerLocations = () => {
    setLoader(true)
    GetData('admin/getCustomerLocations/' + params.id).then((response) => {
      setLoader(false)
      if (response.status === true) {
        const location = response.result
        location.sort((a, b) => (a.isPrimaryLocation < b.isPrimaryLocation ? 1 : -1))
        // eslint-disable-next-line array-callback-return
        location.map((loc) => {
          loc.open = true
        })
        setLocations(location)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getCustomerProfile = (id) => {
    GetData('admin/getCustomerProfileInfo/' + id).then((response) => {
      setLoader(false)
      if (response.status === true) {
        setProfileData(response.result)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getCustomerData = (id, t = false) => {
    setLoader(true)
    GetData('admin/getCustomer/' + id).then((response) => {
      if (response.status === true) {
        setActiveStatus(
          response.result.registrationStatus === 'Active'
        )
        setUserData(response.result)
        customerNotesRef.current.style.height = `${customerNotesRef.current.scrollHeight}px`
        if (t === false) {
          getCustomerProfile(params.id)
        } else {
          setLoader(false)
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const updateCustomerStatus = () => {
    setLoader(true)
    // setDisableModal
    PutData('admin/changeCustomerStatus', {
      id: params.id,
      status: 4
    }).then((response) => {
      if (response.status == true) {
        setLoader(false)
        setActiveStatus(false)
        setDisableModal(false)
        getCustomerData(params.id)
      }
    })
    // : PutData('admin/changeCustomerStatus', {
    //   id: params.id,
    //   status: 1
    // }).then((response) => {
    //   if (response.status == true) {
    //     setLoader(false)
    //     setActiveStatus(true)
    //   }
    // })
  }

  const handleModal = () => {
    setDisableModal((prevState) => !prevState)
    setActiveStatus(true)
  }

  const openProfile = () => {
    setProfileModal(true)
  }

  useEffect(() => {
    if (profileModal || locationModal) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }, [profileModal, locationModal])

  // const manageStatus = (t, values = {}) => {
  //   if (profileData.priceLevels?.name && isPrimaryLocationAdded) {
  //     const formData = {
  //       id: params.id,
  //       customerNotes: customerNotesRef.current.value,
  //       reason: 'approved'
  //     }
  //     let status = 'Approved'
  //     let url = 'admin/approveCustomer'
  //     if (t === 'reject') {
  //       url = 'admin/rejectCustomer'
  //       formData.reason = values.reason
  //       status = 'Rejected'
  //     }
  //     // userData.registrationStatus
  //     setLoader(true)
  //     PutData(url, formData).then((response) => {
  //       setLoader(false)
  //       if (response.status === true) {
  //         getCustomerData(params.id, true)
  //         toastr.success(response.message)
  //         setShowRejectModal(false)
  //       } else {
  //         toastr.error(response.message, 'Error')
  //         setShowRejectModal(false)
  //       }
  //     })
  //   }
  // }

  const isPrimaryLocationAdded = locations?.some(
    (location) =>
      location?.isPrimaryLocation &&
      !isEmpty(location?.primaryVendor) &&
      location?.primaryVendor?.warehouseLocations?.some(
        (d) => d?.isPrimaryLocation
      )
  )
  
  const manageStatus = (t, values = {}) => {
      const formData = {
        id: params.id,
        customerNotes: customerNotesRef.current.value,
        reason: 'approved'
      }

      let url

      if (profileData.priceLevels?.name && isPrimaryLocationAdded) {
        url = 'admin/approveCustomer'
      }
      if (t === 'reject') {
        url = 'admin/rejectCustomer'
        formData.reason = values.reason
      }

      if (formData && url) {
        setLoader(true)
        PutData(url, formData).then((response) => {
          setLoader(false)
          if (response.status === true) {
            getCustomerData(params.id, true)
            toastr.success(response.message)
            setShowRejectModal(false)
          } else {
            toastr.error(response.message, 'Error')
            setShowRejectModal(false)
          }
        })
      }
    
  }

  function handleLocationTab (i, e) {
    e.preventDefault()
    const temp = [...locations]
    if (locations[i].open == true) {
      temp[i].open = false
      setLocations(temp)
    } else {
      temp[i].open = true
      setLocations(temp)
    }
  }

  function handleAdd (e) {
    e.preventDefault()
    setLocationData({})
    setLocationModal(true)
  }

  function handleDelete (id, e) {
    e.preventDefault()
    setLoader(true)

    DeleteData(`admin/removeCustomerLocation/${params.id}/${id}`).then(
      (response) => {
        setLoader(false)
        if (response.status === true) {
          toastr.success(response.message, 'Success')
          getCustomerLocations()
        } else {
          toastr.error(response.message, 'Error')
        }
      }
    )
  }

  // const isPrimaryLocationAdded = locations?.some(
  //   (location) =>
  //     location?.isPrimaryLocation &&
  //     !isEmpty(location?.primaryVendor) &&
  //     location?.primaryVendor?.warehouseLocations?.some(
  //       (d) => d?.isPrimaryLocation
  //     )
  // )

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      reason: ''
    },
    validationSchema: Yup.object({
      reason: Yup.string().required('Please Enter Reason')
    }),
    onSubmit: (values) => {
      manageStatus('reject', values)
    }
  })

  const onChangeHandler = function (e) {
    const target = e.target
    customerNotesRef.current.style.height = '30px'
    customerNotesRef.current.style.height = `${target.scrollHeight}px`
  }

  function handleUpdateCustomerNotes (e) {
    e.preventDefault()
    setLoader(true)
    PostData('admin/updateCustomerNote', {
      customerId: params.id,
      customerNotes: customerNotesRef.current.value
    }).then((response) => {
      if (response.status == true) {
        toastr.success(response.message, 'Success')
        setLoader(false)
      } else {
        toastr.error(response.message, 'Error')
        setLoader(false)
      }
    })
  }

  const handleDisableCheck = (e) => {
    if (e.target.value === 'on') {
      setDisableModal(true)
      setActiveStatus(false)
    }
  }

  // Function to copy on clipboard
  const handleCopy = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2500);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  const handleResendCode = () => {
    setLoader(true)
    PostData('admin/resentEmail', {
      customerId: params.id,
      emailType: 'customerAccountApprove'
    }).then((response) => {
      if (response.status == true) {
        toastr.success(response?.message, 'Success')
        setLoader(false)
      } else {
        toastr.error(response?.message, 'Error')
        setLoader(false)
      }
    })
  }

  return (
    <ProfileModalContext.Provider
      value={{
        profileModal,
        setProfileModal,
        setLoader,
        profileViewModal,
        setProfileViewModal,
        getCustomerData
      }}
    >
      <LocationModalContext.Provider
        value={{
          locationModal,
          setLocationModal,
          locationData,
          setLocationData,
          userData,
          setUserData,
          setLoader,
          getCustomerLocations,
          allLocations,
          setAllLocations
        }}
      >
        <DisableModal show={disableModal} onCloseClick={handleModal} onDeleteClick={updateCustomerStatus}/>
        <div className="page-content">
          <Loader show={loader} />
          <Container fluid={true} className="customer-container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/customer')}
                  className="dripicons-arrow-thin-left me-3"
                ></i>
                <Breadcrumbs
                  title="UI Elements"
                  breadcrumbItem={profileData?.companyName}
                />
              </div>
              {userData.registrationStatus === 'Pending' ||
              userData.registrationStatus === 'Rejected'
                ? (
                <div>
                  <button
                    onClick={() => setShowRejectModal(true)}
                    className="reject-btn"
                    disabled={userData.registrationStatus === 'Rejected'}
                  >
                    Reject
                  </button>
                  <button
                    onClick={() => manageStatus('approve')}
                    className={`approve-btn ${!profileData.priceLevels?.name || !isPrimaryLocationAdded ? 'approve-btn-disabled' : ''}`}
                    id="approve-btn"
                    // disabled={
                    //   !profileData.priceLevels?.name || !isPrimaryLocationAdded
                    // }
                  >
                    Approve
                    <UncontrolledTooltip
                          placement="left"
                          target={'approve-btn'}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow:
                              '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                          }}>
                            <div className="p-3">
                            <p>Please assign Price Level and WD in order to approve Customer&apos;s Application</p>
                            <p className=" mb-1">(i)  To assign Price Level : Go To - Profile &gt; Edit Profile &gt; Select Price Level</p>
                            <p className="mb-1"> (ii) To assign WD : Go To - Location &gt; Edit Location &gt; Assign WD and It&apos;s Primary Warehouse</p>
                            </div>
                          </UncontrolledTooltip>
                  </button>
                </div>
                  )
                : userData.registrationStatus === 'Active' ||
                userData.registrationStatus === 'Disabled'
                  ? (
                <div className="d-flex align-items-center">
                  <div className="pt-1">
                    <span>Status :</span>
                    <span> {activeStatus ? 'Active' : 'Disabled'}</span>
                  </div>

                  <div className="form-check form-switch form-switch-md mb-0 ms-2">
                    <input
                      type="checkbox"
                      className="form-check-input "
                      id="customSwitchsizemd"
                      disabled={!activeStatus}
                      onClick={handleDisableCheck}
                      checked={activeStatus}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
                    )
                  : (
                      ''
                    )}
            </div>
            <Row className="mt-3">
              <Col lg={12}>
                <div className="bottom-customer-card-container">
                  <Card>
                    <CardBody className="px-0">
                      <div className="active-customer-upper-card d-flex justify-content-around">
                        <div>
                          <p className="profile-para">Registration Date</p>
                          <span>
                            {userData.registrationDate
                              ? userData.registrationDate
                              : '--'}
                          </span>
                        </div>
                        <div>
                          <p className="profile-para">Approval Status</p>
                          <span>
                            <span
                              className={
                                userData.registrationStatus === 'Pending' ||
                                userData.registrationStatus === 'Disabled' ||
                                userData.registrationStatus === 'Rejected'
                                  ? 'pending-text'
                                  : 'active-text'
                              }
                            >
                              {userData.registrationStatus}
                            </span>
                          </span>
                        </div>
                        <div>
                          <p className="profile-para">Last Updated</p>
                          <span>
                            {userData.lastUpdated ? userData.lastUpdated : '--'}
                          </span>
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ flex: '2' }}
                        >
                          <button
                            className="assign-wd-button"
                            onClick={() => {
                              getCustomerLocations()
                              setTab('2')
                            }}
                          >
                            Assign WD Location
                          </button>
                        </div>
                        {/* <div>
                      <p>Total Orders</p>
                      <span className="span-blue">{userData.totalOrders ? userData.totalOrders : '--'}</span>
                      <p className="para-blue">View All Orders</p>
                    </div> */}
                        {/* <div>
                      <p>Total Orders Value</p>
                      <span className="span-blue">{userData.totalOrderValue ? userData.totalOrderValue : '--'}</span>
                    </div> */}
                      </div>
                      <hr></hr>
                      <div className="d-flex align-items-center customer-notes-main-div">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label customer-notes-label"
                          id="customer-notes-text"
                        >
                          Customer Notes :
                        </label>
                        <div className="col-md-9 customer-notes-input">
                          <textarea
                            className="form-control"
                            type="text"
                            ref={customerNotesRef}
                            onChange={(e) => onChangeHandler(e)}
                            defaultValue={userData.customerNotes}
                          />
                        </div>
                        <div className="col-md-1 text-center">
                          <button
                            className="customer-notes-save-btn"
                            onClick={(e) => handleUpdateCustomerNotes(e)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>

                <div className="bottom-customer-card-container">
                  <ProfileViewModal profileData={profileData} />
                  <ProfileModal
                    saveProfile={saveProfile}
                    profileData={profileData}
                  />
                  <Card>
                    <CardBody className="px-0">
                      <Nav pills className="ms-5 ps-3 me-5 customer-nav">
                        <div className="d-flex">
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({ active: tab === '1' })}
                              onClick={() => setTab('1')}
                            >
                              <span className="d-flex align-items-center">
                                {' '}
                                Profile &nbsp;
                                {profileData?.customerChangeRequests?.length >
                                  0 && (
                                  <i className="bx bx-info-circle text-danger h5 m-0"></i>
                                )}
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({ active: tab === '2' })}
                              onClick={() => {
                                getCustomerLocations()
                                setTab('2')
                              }}
                            >
                              <span className="d-flex align-items-center">
                                Location &nbsp;
                                {(locations?.filter((location, index) => {
                                  return (
                                    location.customerLocationChangeRequests
                                      ?.length > 0
                                  )
                                })).length > 0 && (
                                  <i className="bx bx-info-circle text-danger h5 m-0"></i>
                                )}
                                {/* {locations?.customerLocationChangeRequests?.length >
                                  0 && (

                                )} */}
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({ active: tab === '3' })}
                              onClick={() => {
                                setTab('3')
                                setDisplayOrders(true)
                              }}
                            >
                              <span className="d-flex align-items-center">
                                Orders &nbsp;
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({ active: tab === '4' })}
                              onClick={() => {
                                setTab('4')
                                setDisplayPaymentInfoList(true)
                              }}
                            >
                              <span className="d-flex align-items-center">
                                Payment Info
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({ active: tab === '5' })}
                              onClick={() => {
                                setTab('5')
                                setDisplayStaff(true)
                              }}
                            >
                              <span className="d-flex align-items-center">
                                Staff
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({ active: tab === '6' })}
                              onClick={() => {
                                setTab('6')
                                setDisplayAssign(true)
                              }}
                            >
                              <span className="d-flex align-items-center">
                                Assign Brands and Categories
                              </span>
                            </NavLink>
                          </NavItem>
                        </div>
                      </Nav>
                      <hr className=" mt-0 mb-4"></hr>
                      <TabContent activeTab={tab}>
                        <TabPane tabId="1">
                          <div className="bottom-customer-card-inner-location">
                            <div className="d-flex justify-content-between">
                              <h5 className="section-main-heading-blue">
                                Business Information
                                {profileData?.customerChangeRequests?.length >
                                0
                                  ? (
                                  <button
                                    onClick={() => setProfileViewModal(true)}
                                    className="approve-btn new--req-btn"
                                  >
                                    Change Request Received
                                  </button>
                                    )
                                  : null}
                              </h5>
                              <div
                                style={{ cursor: 'pointer' }}
                                className="profile-edit-btn"
                                onClick={(e) => {
                                  e.preventDefault()
                                  openProfile()
                                }}
                              >
                                <button className='assign-wd-button px-2'>
                                  <i className="bx bxs-edit-alt ms-0 "></i>
                                  <span style={{ fontSize: '14px' }}>Edit</span>
                                </button>
                              </div>
                            </div>
                            <div className="bottom-customer-card-inner-profile">
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label>Company Name</Label>
                                  <p>
                                    {profileData.companyName
                                      ? profileData.companyName
                                      : '--'}
                                  </p>
                                </div>
                                <div style={{ flex: '1' }}>
                                  <Label>Email Address {userData.registrationStatus === 'Approved' && <button className="resend-verification" onClick={handleResendCode} title="Click to resend the verification email">Resend Verification Email</button>}</Label>
                                  <p onClick={() => handleCopy(profileData.email)}>
                                    {profileData.email
                                      ? profileData.email
                                      : '--'}{' '}
                                    {profileData.emaiVerified === 'Verified'
                                      ? (
                                      <span className="verified-text">
                                        <img src={tickIcon} alt="tick-icon" />{' '}
                                        Verified
                                      </span>
                                        )
                                      : (
                                          ''
                                        )}
                                  </p>
                                  {isCopied && <span className="copied-message">Copied !</span>}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label>Phone Number</Label>
                                  <p>
                                    <NumberFormat value={profileData.mobile ? profileData.mobile : '--'} displayType={'text'} format="+1 (###) ###-####"/>
                                    {/* {profileData.mobile
                                      ? profileData.mobile
                                      : '--'} */}
                                  </p>
                                </div>
                                <div style={{ flex: '1' }}>
                                  <Label>Chain Affiliation</Label>
                                  <p>
                                    {chainAffilation &&
                                      (profileData.chainAffiliation
                                        ? (
                                            profileData.chainAffiliation ===
                                        'other'
                                              ? (
                                          <span>
                                            {'Other - ' +
                                              profileData.chainAffiliationOther}
                                          </span>
                                                )
                                              : (
                                                  chainAffilation[
                                                    profileData.chainAffiliation
                                                  ]
                                                )
                                          )
                                        : (
                                            '--'
                                          ))}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label>Date Company Started</Label>
                                  <p>
                                    {profileData.dateCompanyStarted
                                      ? moment(
                                        profileData.dateCompanyStarted
                                      ).format('MM-DD-YYYY')
                                      : '--'}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label className="heading-label mb-3">
                                    Ownership Type* (Check One)
                                  </Label>
                                  {Object.keys(ownershipType).length
                                    ? Object.keys(ownershipType).map((k, i) => {
                                      return (
                                          <div
                                            key={i}
                                            className="form-check mb-1"
                                          >
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value={k}
                                              readOnly={true}
                                              checked={
                                                profileData.ownershipType === k
                                              }
                                              id={'ownership-' + k}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={'ownership-' + k}
                                            >
                                              {ownershipType[k]}
                                            </label>
                                          </div>
                                      )
                                    })
                                    : '--'}
                                </div>
                                <div style={{ flex: '1' }}>
                                  <Label className=" heading-label mb-3">
                                    Type of Business* (Check One)
                                  </Label>
                                  {Object.keys(businessType).length
                                    ? Object.keys(businessType).map((k, i) => {
                                      return (
                                          <div
                                            key={i}
                                            className="form-check mb-1"
                                          >
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              readOnly={true}
                                              value={k}
                                              checked={
                                                profileData.businessType === k
                                              }
                                              id={'businessType-' + k}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={'businessType-' + k}
                                            >
                                              {businessType[k]}
                                            </label>
                                          </div>
                                      )
                                    })
                                    : '--'}
                                  {profileData.businessType === 'other'
                                    ? (
                                    <p>
                                      {'Other - ' +
                                        profileData.businessTypeOther}
                                    </p>
                                      )
                                    : (
                                        ''
                                      )}
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                            <h5 className="section-main-heading-blue">
                              Contact Information
                            </h5>
                            <div className="bottom-customer-card-inner-profile">
                              <h5 className="section-inner-heading">
                                Primary / Owner
                              </h5>
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label>First Name</Label>
                                  <p>
                                    {profileData.primaryFirstName
                                      ? profileData.primaryFirstName
                                      : '--'}
                                  </p>
                                </div>
                                <div style={{ flex: '1' }}>
                                  <Label>Last Name</Label>
                                  <p>
                                    {profileData.primaryLastName
                                      ? profileData.primaryLastName
                                      : '--'}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label>Email Address</Label>
                                  <p>
                                    {profileData.primaryEmail
                                      ? profileData.primaryEmail
                                      : '--'}
                                  </p>
                                </div>
                                <div style={{ flex: '1' }}>
                                  <Label>Phone Number</Label>
                                  <p>
                                    <NumberFormat value={profileData.primaryPhone ? profileData.primaryPhone : '--'} displayType={'text'} format="+1 (###) ###-####"/>
                                    {/* {profileData.primaryPhone
                                      ? profileData.primaryPhone
                                      : '--'} */}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label>Title</Label>
                                  <p>
                                    {profileData.primaryTitle
                                      ? profileData.primaryTitle
                                      : '--'}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="bottom-customer-card-inner-profile">
                              <h5 className="section-inner-heading">
                                Alternate
                              </h5>
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label>First Name</Label>
                                  <p>
                                    {profileData.alternateFirstName
                                      ? profileData.alternateFirstName
                                      : '--'}
                                  </p>
                                </div>
                                <div style={{ flex: '1' }}>
                                  <Label>Last Name</Label>
                                  <p>
                                    {profileData.alternateLastName
                                      ? profileData.alternateLastName
                                      : '--'}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label>Email Address</Label>
                                  <p>
                                    {profileData.alternateEmail
                                      ? profileData.alternateEmail
                                      : '--'}
                                  </p>
                                </div>
                                <div style={{ flex: '1' }}>
                                  <Label>Phone Number</Label>
                                  <p>
                                    <NumberFormat value={profileData.alternatePhone ? profileData.alternatePhone : '--'} displayType={'text'} format="+1 (###) ###-####" />
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label>Title</Label>
                                  <p>
                                    {profileData.alternateTitle
                                      ? profileData.alternateTitle
                                      : '--'}
                                  </p>
                                </div>
                              </div>
                            </div> */}
                            <hr></hr>
                            <h5 className="section-main-heading-blue">
                              Tax Information
                            </h5>
                            <div className="bottom-customer-card-inner-profile">
                              <div className="d-flex justify-content-between customer-profile-mobile">
                                <div style={{ flex: '1' }}>
                                  <Label>Resale Tax Number</Label>
                                  <p>
                                    {profileData.reSaleTaxNumber
                                      ? profileData.reSaleTaxNumber
                                      : '--'}
                                  </p>
                                </div>
                                <div style={{ flex: '1' }}>
                                  <Label>Document Uploaded</Label>
                                  {profileData?.customerDocuments?.length !=
                                  0
                                    ? (
                                        profileData?.customerDocuments?.map(
                                          (document, key) => {
                                            return (
                                          <p key={key}>
                                            {document.documentUrl != null
                                              ? (
                                              <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href={document.documentUrl}
                                              >
                                                {document.name}
                                              </a>
                                                )
                                              : (
                                              <p>--</p>
                                                )}
                                          </p>
                                            )
                                          }
                                        )
                                      )
                                    : (
                                    <p>--</p>
                                      )}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div style={{ flex: '1' }}>
                                  <Label>Price Groups</Label>
                                  <p
                                    style={{
                                      color: profileData.priceLevels
                                        ? ''
                                        : '#d54242'
                                    }}
                                  >
                                    {profileData.priceLevels ? `${profileData.priceLevels.name} ${profileData?.priceLevels?.name !== 'National Account' ? `- ${profileData?.priceLevels?.value}` : ''}`: 'Not selected'}
                                  </p>
                                </div>
                                <div style={{ flex: '1' }}>
                                  <Label>Dropship Allowed</Label>
                                  <p>
                                    {profileData?.allow_dropship
                                      ? 'Yes'
                                      : 'No'}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <div className="bottom-customer-card-inner-location customer-location">
                              <hr></hr>
                              {locations.map((location, index) => {
                                return (
                                  <div key={index}>
                                    <div className="d-flex align-items-center justify-content-between location-info-row">
                                      <div className="d-flex align-items-center">
                                        <span className="location-number cursor-pointer">
                                          <span
                                            onClick={(e) => {
                                              handleLocationTab(index, e)
                                            }}
                                          >
                                            {' '}
                                            Location {index + 1}
                                          </span>
                                          {locations[index]
                                            ?.customerLocationChangeRequests
                                            ?.length > 0
                                            ? (
                                            <LocationViewModal
                                              locationData={locations[index]}
                                              getLocations={
                                                getCustomerLocations
                                              }
                                            />
                                              )
                                            : null}
                                        </span>
                                        {!!location.isPrimaryLocation && (
                                          <span className="primary-location">
                                            Primary Location
                                          </span>
                                        )}
                                         {!!location.isCorporate && (
                                          <span className="location-corporate">
                                            Corporate Location
                                          </span>
                                        )}
                                        {!!location.isCustomer && (
                                          <span className="location-customer">
                                            Customer Location
                                          </span>
                                        )}
                                      </div>
                                      <div className="d-flex align-items-center delete-location-button">
                                        {(!location.isPrimaryLocation && !location.isCorporate) && (
                                          <img
                                            src={delIcon}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>
                                              handleDelete(location.id, e)
                                            }
                                          />
                                        )}
                                        <>
                                          {location.open
                                            ? (
                                            <i
                                              style={{ cursor: 'pointer' }}
                                              className="bx bx-minus"
                                              onClick={(e) => {
                                                handleLocationTab(index, e)
                                              }}
                                            ></i>
                                              )
                                            : (
                                            <i
                                              style={{ cursor: 'pointer' }}
                                              className="bx bx-plus"
                                              onClick={(e) => {
                                                handleLocationTab(index, e)
                                              }}
                                            ></i>
                                              )}
                                        </>
                                      </div>
                                    </div>
                                    {location.open && (
                                      <LocationInfo location={location} locations={locations} />
                                    )}
                                    <hr></hr>
                                    {locationModal && (
                                      <LocationModal
                                        locationData={locationData}
                                        setLoader={setLoader}
                                      />
                                    )}
                                  </div>
                                )
                              })}
                              <button
                                className="add-new-btn "
                                onClick={(e) => handleAdd(e)}
                              >
                                + Add New Location
                              </button>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <OrderList display = { displayOrders } />
                        </TabPane>
                        <TabPane tabId="4">
                          <PaymentInfoList display = { displayPaymentInfoList } />
                        </TabPane>
                        <TabPane tabId="5">
                          <StaffList display = { displayStaff } />
                        </TabPane>
                        <TabPane tabId="6">
                          <AssignBrandsCategories display = { displayAssign } />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Modal
              size="md"
              isOpen={showRejectModal}
              toggle={() => {
                // eslint-disable-next-line no-undef
                tog_large()
              }}
              centered
            >
              <div className="modal-header border-0">
                <button
                  onClick={() => {
                    setShowRejectModal(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body rejected-modal-container">
                <p className="reject-modal-heading-red">
                  Are you sure you want to reject customer?
                </p>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <p className="reject-modal-heading-black">
                    Rejection Reason*
                  </p>
                  <textarea
                    name="reason"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.reason || ''}
                    invalid={
                      !!(validation.touched.reason && validation.errors.reason)
                    }
                  ></textarea>
                  {validation.touched.reason && validation.errors.reason
                    ? (
                    <FormFeedback type="invalid" style={{ display: 'block' }}>
                      {validation.errors.reason}
                    </FormFeedback>
                      )
                    : null}
                  <div className="text-center mt-4 mb-2">
                    <button
                      className="reject-no-btn me-3"
                      onClick={() => {
                        setShowRejectModal(false)
                      }}
                    >
                      No
                    </button>
                    <button className="reject-submit-btn" type="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </Modal>
          </Container>
        </div>
      </LocationModalContext.Provider>
    </ProfileModalContext.Provider>
  )
}

function LocationInfo ({ location, locations }) {
  const { locationModal, setLocationModal, locationData, setLocationData, setAllLocations } =
    useContext(LocationModalContext)
  return (
    <div className="location-details-container">
      <div className="d-flex align-items-center justify-content-between">
        <span className="location-details-big-heading">
          {location?.locationName}
        </span>
        <div
          className="edit-btn profile-edit-btn"
          onClick={(e) => {
            e.preventDefault()
            setLocationData(location)
            setLocationModal(true)
            setAllLocations(locations)
          }}
        >
            <i className="bx bxs-edit-alt "></i>
            <span>Edit</span>
        </div>
      </div>
      <div className="d-flex justify-content-between location-container-inner">
        <div className="location-container-inner">
          <p className="location-details-heading">Shipping Address</p>
          <div>
            <div>
              {location.shippingCityId
                ? (
                <div>
                  <p>{location.shippingAddress}</p>
                  <p>{location.shippingStreet}</p>
                  <p>{`${location?.cities?.shippingCityName}, ${location?.states?.shippingStateName}`}</p>
                  <p>{location.shippingZipCode}</p>
                </div>
                  )
                : (
                    '--'
                  )}
            </div>
          </div>
        </div>
        <div className="location-container-inner ps-4">
          <p className="location-details-heading">Contact Information</p>
          <div>
            {location.contactTitle
              ? (
              <>
                <p>{location.contactTitle ?? ''}</p>
                <p>
                  {location.contactFirstName
                    ? location.contactFirstName + ' ' + location.contactLastName
                    : ''}
                </p>
                <p>{location.contactEmail ?? ''}</p>
                <p>
                  <NumberFormat value={ location.contactPhone ?? '' } displayType={'text'} format="+1 (###) ###-####"/>
                  {/* {location.contactPhone ?? ''} */}
                  </p>
              </>
                )
              : (
                  '--'
                )}
          </div>
        </div>
        <div className="location-container-inner ps-4">
          <p className="location-details-heading">Primary WD</p>
          <div>
            {location.primaryVendor
              ? (
              <>
                {location.primaryVendor?.companyName || (
                  <p className="not-assigned-text">Not Assigned</p>
                )}
              </>
                )
              : (
                  '--'
                )}
            <p className="location-details-heading pt-3">Warehouse</p>
            <p>
              {location.primaryVendor?.warehouseLocations
                ?.map((d) => d?.locationName)
                ?.join?.(', ') || (
                <>
                  <p className="not-assigned-text">Not Assigned</p>
                  <p className="edit-assign-wd-text">
                    Click on Edit to assign WD to this location
                  </p>
                </>
              )}
            </p>
          </div>
        </div>

        <div className="location-container-inner ps-4">
          <p className="location-details-heading">Secondary WD</p>
          <div>
            {location.secondaryVendor
              ? (
              <>
                {location.secondaryVendor?.companyName || (
                  <p className="not-assigned-text">Not Assigned</p>
                )}
              </>
                )
              : (
                  '--'
                )}
            <p className="location-details-heading pt-3">Warehouse</p>
            <p>
              {location.secondaryVendor?.warehouseLocations
                ?.map?.((d) => d?.locationName)
                ?.join?.(', ') || (
                <>
                  <p className="not-assigned-text">Not Assigned</p>
                  <p className="edit-assign-wd-text">
                    Click on Edit to assign WD to this location
                  </p>
                </>
              )}
            </p>
          </div>
        </div>

        <div className="location-container-inner ps-4">
          <p className="location-details-heading">Alternate WD</p>
          <div>
            {location.alternateVendor
              ? (
              <>
                {location.alternateVendor?.companyName || (
                  <p className="not-assigned-text">Not Assigned</p>
                )}
              </>
                )
              : (
                  '--'
                )}
            <p className="location-details-heading pt-3">Warehouse</p>
            <p>
              {location.alternateVendor?.warehouseLocations
                ?.map((d) => d?.locationName)
                ?.join?.(', ') || (
                <>
                  <p className="not-assigned-text">Not Assigned</p>
                  <p className="edit-assign-wd-text">
                    Click on Edit to assign WD to this location
                  </p>
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
LocationInfo.propTypes = {
  location: PropTypes.object
}
