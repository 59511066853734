import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import "../AccountPricing/AccountPricing.scss";
import { PostData, GetData } from "apiHandler";
import toastr from "toastr";
import Loader from "../../components/Loader";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { FormControl, InputGroup } from "react-bootstrap";

const CartSettings = () => {
  const [orderConfigList, setOrderConfigList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tableMessage, setTableMessage] = useState("Please wait loading...");

  useEffect(() => {
    getOrderConfig();
  }, []);

  //-----------------------Function to get order configuration list data----------------
  const getOrderConfig = () => {
    setLoader(true);
    let url = "admin/getOrderConfigurations";

    GetData(url).then((response) => {
      setLoader(false);
      if (response.status === true) {
        setOrderConfigList(response?.result);
      } else {
        toastr.error(response?.message, "Error");
      }
    });
  };

  //   -------------------------Function to update shipping-----------------------------
  const updateOrderConfig = (key, check) => {
    const orderConfigListTemp = [...orderConfigList];
    if (check === true) {
      const payload = {
        title: orderConfigListTemp[key].title,
        value: orderConfigListTemp[key].value,
        note: orderConfigListTemp[key].note,
        code: orderConfigListTemp[key].code,
      };
      setLoader(true);
      PostData("admin/updateOrderConfiguration", payload).then((response) => {
        setLoader(false);
        if (response.status === true) {
          orderConfigListTemp[key].update = false;
          setOrderConfigList(orderConfigListTemp);
          getOrderConfig();
          toastr.success(response.message, "Success");
        } else {
          toastr.error(response.message, "Error");
        }
      });
    } else {
      getOrderConfig();
    }
  };

  // --------------------function to handle shipping price------------------
  const handleInputPrice = (e, key) => {
    if (isNaN(e.target.value)) {
      return;
    }

    const orderConfigListTemp = [...orderConfigList];
    orderConfigListTemp[key].value = e.target.value;
    orderConfigListTemp[key].update = true;
    setOrderConfigList(orderConfigListTemp);
  };

  // --------------------function to handle shipping note------------------
  const handleInputNote = (e, key) => {
    const orderConfigListTemp = [...orderConfigList];
    orderConfigListTemp[key].note = e.target.value;
    orderConfigListTemp[key].update = true;
    setOrderConfigList(orderConfigListTemp);
  };

  return (
    <div className="page-content">
      <Loader show={loader} />
      <Container fluid={true} className="customer-container">
        <div className="breadcrumb-main-nav">
          <Breadcrumbs title="UI Elements" breadcrumbItem="Cart Settings" />
        </div>
        <Row className="mt-3">
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={3} className="heading-tab">
                    <p>Title</p>
                  </Col>
                  <Col lg={3} className="heading-tab">
                    <p>Notes</p>
                  </Col>
                  <Col lg={2} className="heading-tab">
                    <p>Value</p>
                  </Col>
                  <Col lg={2} className="heading-tab">
                    <p></p>
                  </Col>
                </Row>
                {orderConfigList?.length > 0 ? (
                  orderConfigList?.map((list, key) => {
                    return (
                      <Row key={key}>
                        <Col lg={3} className="input-tab">
                          <div><b>{list?.title}</b></div>
                        </Col>
                        <Col lg={3} className="input-tab">
                          <input
                            placeholder="Enter setting note"
                            type="text"
                            className="input-sell-price w-100 ps-2"
                            value={list?.note}
                            onChange={(e) => handleInputNote(e, key)}
                          />
                        </Col>
                        <Col lg={2} className="input-tab">
                          <InputGroup>
                            <FormControl
                              type="text"
                              className="text-center"
                              value={list?.value}
                              onChange={(e) => handleInputPrice(e, key)}
                            />
                            <InputGroup.Text>{list?.code === "MIN_CART_TOTAL" || list?.code === "FREIGHT_CART_TOTAL" ? '$' : 'Qty'}
                            </InputGroup.Text>
                          </InputGroup>
                        </Col>
                        {/* <Col lg={2} className="input-tab">
                          <input
                            type="text"
                            className="text-center input-sell-price"
                            value={list?.value}
                            onChange={(e) => handleInputPrice(e, key)}
                          />
                        </Col> */}
                        <Col
                          lg={2}
                          className="input-tab d-flex justify-content-center align-items-center"
                        >
                          {list.update && (
                            <div className="mx-1">
                              <i
                                onClick={() => updateOrderConfig(key, true)}
                                className="fas fa-check-circle input-icon-tick mr-1"
                              ></i>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <i
                                onClick={() => updateOrderConfig(key, false)}
                                className="fas fa-times-circle input-icon-cross"
                              ></i>
                            </div>
                          )}
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Row>
                    {" "}
                    <Col lg={12} sm={12} className="input-tab">
                      {" "}
                      {tableMessage}{" "}
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CartSettings;
